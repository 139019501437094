<template>
  <mobile-screen :header="true" screen-class="levels-screen gray-bg icon-app1">
    <template v-slot:header>
      <top-header-menu-wrapper
        menu-class="level-header icon-hea1"
        :helpOnline="
          Boolean(
            helpOnline[helpSlug] &&
              helpOnline[helpSlug].name &&
              helpOnline[helpSlug].content
          )
        "
        :helpOnlineRoute="{
          name:
            this.backLinkName.indexOf('r_edit') !== -1
              ? 'r_resources-help-online-edit-level'
              : 'r_resources-help-online-add-level'
        }"
      >
        <template v-slot:left>
          <router-link
            :to="{
              name: backLinkName,
              params: $route.params
            }"
          >
            <icon icon="#cx-hea1-arrow-left" />
          </router-link>
        </template>
        <div class="component-title">
          {{ displayLabelName("level", "level", "levels") }}
        </div>
        <template v-slot:right>
          <button @click="toggleSearch">
            <icon icon="#cx-hea1-search" />
          </button>
        </template>
      </top-header-menu-wrapper>
      <section class="search-wrapper level-search" v-if="search">
        <div class="search-field">
          <form @submit.prevent="submitSearch" novalidate>
            <input
              type="text"
              ref="searchForm"
              @keyup="searchQuery = $event.target.value"
              :placeholder="displayLabelName('level', 'level', 'search')"
            />
            <div class="icon search">
              <icon icon="#cx-app1-search-16x16" width="16" height="16" />
            </div>
          </form>
        </div>
      </section>
    </template>
    <ul class="clebex-item-section">
      <li class="clebex-item-section-item">
        <div class="clebex-item-content-wrapper">
          <dl class="clebex-item-dl justify-start">
            <dt class="clebex-item-dt">
              <div class="checkbox slide">
                <input
                  id="timeZoneRadioNotDefined"
                  type="checkbox"
                  v-model="notDefined"
                />
                <label for="timeZoneRadioNotDefined"></label>
              </div>
            </dt>
            <dd class="clebex-item-dd justify-start">
              {{ displayLabelName("level", "level", "not-defined") }}
            </dd>
          </dl>
        </div>
      </li>
    </ul>

    <ul
      class="clebex-item-section pill"
      v-if="listOfLevels && listOfLevels.length"
    >
      <li
        class="clebex-item-section-item"
        v-for="lvl in listOfLevels"
        :key="lvl.id"
      >
        <div class="clebex-item-content-wrapper">
          <div class="checkbox tiny block alt">
            <input
              type="radio"
              :id="`res-level${lvl.id}`"
              name="res-level"
              :value="lvl.id"
              :checked="
                selectedResourceLevel && selectedResourceLevel.id === lvl.id
              "
              @change="setSelectedResourceLevel(lvl)"
            />
            <label :for="`res-level${lvl.id}`">
              <icon icon="#cx-app1-checkmark" />
              <dl class="clebex-item-dl no-left-padding">
                <dt class="clebex-item-dt">
                  <div
                    class="clebex-item-parent-list"
                    v-if="lvl.all_parents && lvl.all_parents.length"
                  >
                    <span
                      class="parent-name"
                      v-for="parent in lvl.all_parents"
                      :key="parent.id"
                    >
                      {{ parent.name }}
                    </span>
                  </div>
                </dt>
                <dd class="clebex-item-dd">
                  <div class="link">
                    {{ lvl.name }}
                  </div>
                </dd>
              </dl>
            </label>
          </div>
        </div>
      </li>
    </ul>
  </mobile-screen>
  <router-view />
</template>

<script>
import { mapActions, mapState, mapGetters } from "vuex";
import MobileScreen from "@/layouts/MobileScreen";
import TopHeaderMenuWrapper from "@/components/global/TopHeaderMenuWrapper";
import helpOnlineMixin from "@/services/mixins/help_online/help-online-mixin";

export default {
  name: "ResourceLevels",
  mixins: [helpOnlineMixin],
  data() {
    return {
      notDefined: false,
      searchQuery: "",
      mode: null,
      search: null,
      favoritesActive: null,
      showSearchResults: null,
      helpSlug: "resources-levels"
    };
  },
  created() {
    if (this.backLinkName.indexOf("r_edit") !== -1) {
      const { resource } = this;
      if (
        resource &&
        resource.data &&
        this.canItem(this.resource.data, "update")
      ) {
        this.getLevelData();
        this.getCountries();
      } else {
        this.$router.push({ name: "r_resources" });
      }
    } else {
      this.getLevelData();
      this.getCountries();
    }
  },
  computed: {
    ...mapState("level", ["levels", "level", "favoriteLevels"]),
    ...mapState("resource", ["resource", "selectedResourceLevel"]),
    ...mapGetters("level", ["parentLevels"]),
    routeParam() {
      const { level_id } = this.$route.params;
      return level_id;
    },
    listOfLevels() {
      if (this.levels && this.levels.data && this.levels.data.length) {
        return this.levels.data;
      }
      return null;
    }
  },
  watch: {
    routeParam() {
      this.$store.commit("level/setLevelData", null, { root: true });
      this.getLevelData();
    },
    notDefined() {
      if (this.notDefined) {
        this.setSelectedResourceLevel(null);
      }
    },
    selectedResourceLevel() {
      if (this.selectedResourceLevel) {
        if (this.notDefined) {
          this.notDefined = false;
        }
        this.$router.push({ name: this.backLinkName });
      }
    },
    selectedLevelId() {
      const { resource } = this;
      if (resource && resource.data && resource.data.level) {
        return resource.data.level.id;
      }
      return null;
    }
  },
  methods: {
    ...mapActions("level", ["getLevels", "resetLevelsState"]),
    ...mapActions("country", ["getCountries"]),
    ...mapActions("resource", ["setSelectedResourceLevel"]),
    getLevelData() {
      const query = this.searchQuery;
      if (query) {
        return this.getLevels({
          query,
          depth: "BOTTOM",
          includes: ["all_parents"]
        });
      } else {
        this.getLevels({
          depth: "BOTTOM",
          includes: ["all_parents"]
        });
      }
    },
    toggleSearch() {
      this.search = !this.search;
    },
    hasLocation(level) {
      return (
        level.address_line_1 ||
        level.address_line_2 ||
        level.city ||
        level["zip"] ||
        level.state
      );
    },
    openLevels() {
      this.getLevelData();
      this.favoritesActive = false;
    },
    submitSearch() {
      this.showSearchResults = !!this.searchQuery;
      this.$store.commit("level/setLevelsState", null, { root: true });
      this.openLevels();
    }
  },
  beforeUnmount() {
    this.resetLevelsState();
  },
  components: {
    MobileScreen,
    TopHeaderMenuWrapper
  },
  props: {
    backLinkName: {
      type: String,
      required: true
    },
    forwardLinkName: {
      type: String,
      required: true
    }
  }
};
</script>
